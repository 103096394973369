import { TranslateService } from '@ngx-translate/core';

export enum FormacaoBancoDadosEnum {
  EM_FORMACAO,
  CALCULO
}

export function getValoresEnumTraduzidosFormacaoBancoDados(translateService: TranslateService): any[] {
  const retorno: any[] = [];

  retorno.push({ label: getFormacaoBancoDados(FormacaoBancoDadosEnum.CALCULO, translateService), valor: 'CALCULO', id: 3 });
  retorno.push({ label: getFormacaoBancoDados(FormacaoBancoDadosEnum.EM_FORMACAO, translateService), valor: 'EM_FORMACAO', id: 2 });

  return retorno;
}


export function getFormacaoBancoDados(tipo: FormacaoBancoDadosEnum, translateService: TranslateService): string {
  switch (tipo) {
    case FormacaoBancoDadosEnum.CALCULO:
      return translateService.instant('formacaoBancoDadosEnum.calculo');
    case FormacaoBancoDadosEnum.EM_FORMACAO:
      return translateService.instant('formacaoBancoDadosEnum.emFormacao');
  }
}
